@use '../themes/fonts';

@each $name, $size in fonts.$font-sizes {
  .text-#{$name} {
    font-size: $size !important;
  }
}

.text-bold {
  @include fonts.font-bold();
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-underline {
  text-decoration: underline;
}

$text-alignments: left, center, right;
@each $alignment in $text-alignments {
  .text-#{$alignment} {
    text-align: $alignment;
  }
}

$text-spacing-spacer: 0.125px;
@for $i from 0 to 32 {
  .text-spacing-#{$i} {
    letter-spacing: $i * $text-spacing-spacer;
  }
}
