/* Font Weight */
$font-weight-thin: 100;
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

/* Font Size */
$font-size-display-lg: 2rem; // 32px
$font-size-display-md: 1.75rem; // 28px
$font-size-display-sm: 1.5rem; // 24px
$font-size-display-xs: 1.25rem; // 20px
$font-size-lg: 1.125rem; // 18px
$font-size-md: 1rem; // 16px
$font-size-sm: 0.875rem; // 14px
$font-size-macro: 0.75rem; // 12px
$font-size-macro-sm: 0.688rem; // 11px
$font-size-macro-caption: 0.5rem; // 8px

/* Line Height */
$line-height-8: 2.5rem; // 40px
$line-height-7: 2.25rem; // 36px
$line-height-6: 2rem; // 32px
$line-height-5: 1.75rem; // 28px
$line-height-4: 1.625rem; // 26px
$line-height-3: 1.375rem; // 22px
$line-height-2: 1.125rem; // 18px
$line-height-1: 1rem; // 16px

$font-weights: (
  thin: $font-weight-thin,
  regular: $font-weight-regular,
  bold: $font-weight-bold,
  black: $font-weight-black,
);

@mixin text-display-lg(
  $font-weight: regular
){
  font-weight: map-get($font-weights, $font-weight);
  font-size: $font-size-display-lg;
  line-height: $line-height-8;
}

@mixin text-display-md(
  $font-weight: regular
){
  font-weight: map-get($font-weights, $font-weight);
  font-size: $font-size-display-md;
  line-height: $line-height-7;
}

@mixin text-display-sm(
  $font-weight: regular
){
  font-weight: map-get($font-weights, $font-weight);
  font-size: $font-size-display-sm;
  line-height: $line-height-6;
}

@mixin text-display-xs(
  $font-weight: regular
){
  font-weight: map-get($font-weights, $font-weight);
  font-size: $font-size-display-xs;
  line-height: $line-height-5;
}

@mixin text-lg(
  $font-weight: regular
){
  font-weight: map-get($font-weights, $font-weight);
  font-size: $font-size-lg;
  line-height: $line-height-5;
}

@mixin text-md(
  $font-weight: regular
){
  font-weight: map-get($font-weights, $font-weight);
  font-size: $font-size-md;
  line-height: $line-height-4;
}

@mixin text-sm(
  $font-weight: regular
){
  font-weight: map-get($font-weights, $font-weight);
  font-size: $font-size-sm;
  line-height: $line-height-3;
}

@mixin text-macro(
  $font-weight: regular
){
  font-weight: map-get($font-weights, $font-weight);
  font-size: $font-size-macro;
  line-height: $line-height-2;
}

@mixin text-macro-sm(
  $font-weight: regular
){
  font-weight: map-get($font-weights, $font-weight);
  font-size: $font-size-macro-sm;
  line-height: $line-height-2;
}

@mixin text-macro-caption(
  $font-weight: regular
){
  font-weight: map-get($font-weights, $font-weight);
  font-size: $font-size-macro-caption;
  line-height: $line-height-1;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-regular);
  line-height: 1.2;
  color: var(--font-color-dark);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

p,
a,
h6 {
  font-size: 1rem;
}
